/* Built In Imports */
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import config from '@config';
import { usePageType } from '@hooks/usePageType';
import { TopBannerSt } from '@ieo/Common/CompLangConfig';
import { gtmObject } from '@ieo/Components/Form/ieo/api/analyticsAPi';
import { mixPanel } from '@utils/Mixpanel';

/* Services */
import { isVisInViewport } from '@components/Utility/Shared/SharedService';
import { programTypes } from '@ieo/Components/Form/ieo/utils';

let isFormVisible = false;
/**
 *
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang
 * @returns
 */
const RegisterStickyButton = ({ sectionContent, region, lang, pageData }) => {
  const router = useRouter();
  const [isVisible, setIsVisible] = useState(false);
  const pageConfigVal =
    pageData?.pageConfig?.length &&
    !pageData?.pageConfig[0]?.value?.disabled &&
    pageData?.pageConfig[0].value;
  const pathname = usePathname();
  const isV4 = pathname.indexOf('inner-engineering-v4') > -1;
  const { isGiftPage } = usePageType();

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const gtmClick = () => {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'IE_Register_Now',
        IE_LP_Location: 'Sticky_CTA',
      });

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: 'add_to_wishlist',
        ecommerce: {
          currency: '', // currency code (Ex - INR,USD,AUD etc.)
          value: '',
          items: [
            {
              item_id: programTypes.IEO, // id of the program
              item_name: 'TYLS Inner Engineering',
              affiliation: '',
              coupon: '',
              discount: 0, //Actual Discount if any (actual price - pay price)
              index: '',
              item_brand: '', // Country Code (Ex - US,IN etc.)
              item_category: '', // Language (Ex - English,Spanish etc.)
              item_category2: '', // Bay Name (not reuired for IE)
              item_category3: '', // New_User or Repeat_User
              item_category4: 'Sticky_CTA',
              item_category5: '',
              item_list_id: '',
              item_list_name: '',
              item_variant: '',
              location_id: '',
              price: '',
              quantity: '',
            },
          ],
        },
      });

      gtmObject({
        event: isGiftPage ? 'IE_Gift_Inner_Engineering' : 'IE_Register_Now',
        IE_LP_Location: 'Sticky_CTA',
        event1: 'view_item',
        item_name: isGiftPage
          ? 'Gift Inner Engineering'
          : 'TYLS Inner Engineering',
        item_category4: 'Sticky_CTA',
      });
    }
    const Mixpanel = mixPanel(router.asPath);
    if (Object.keys(Mixpanel)?.length > 0) {
      Mixpanel.track('ie_registration_cta', {
        cta: 'Sticky Button',
        element: sectionContent[0]?.buttonText,
        language: config.lngList[lang.toLowerCase()],
      });
    }
  };

  const listenToScroll = () => {
    const enrollSectionDiv = document.getElementById('ieo-enroll-section');
    const prgFeesDiv =
      document.getElementById('ieo-program-fees') ||
      document.getElementById('ieo-program-fees-sza');
    const footerDiv = document.getElementById('footer');
    const ieoPrgSectionDiv = document.getElementById('ieo-program-section');

    const heightToHideFrom = window.innerHeight * 1.4;

    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      const inViewPort = enrollSectionDiv && isVisInViewport(enrollSectionDiv);
      const inFeeViewPort = prgFeesDiv && isVisInViewport(prgFeesDiv);
      const footerInViewPort = footerDiv && isVisInViewport(footerDiv);
      const ieoPrgViewPort = footerDiv && isVisInViewport(ieoPrgSectionDiv);
      // console.log('winScroll', winScroll, enrollSectionDiv, inViewPort);
      if (inFeeViewPort || inViewPort || footerInViewPort || ieoPrgViewPort) {
        setIsVisible(false);
      } else if (!sectionContent[0]?.buttonText) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    } else {
      setIsVisible(false);
    }
  };
  // console.log('==> button ', sectionContent[0]?.buttonText ? true : false);

  return (
    isVisible && (
      <Box
        position="fixed"
        top={{ base: 'unset', md: '30%' }}
        bottom={{ base: '2%', md: 'unset' }}
        zIndex="100"
        left={{ base: '50%', md: 'unset' }}
        right={{ base: 'unset', md: '0px' }}
        width={{
          base: 'auto',
          md: pageConfigVal?.btn?.icon ? '156px' : 'auto',
        }}
        maxW={{ base: 'auto', md: '150px', lg: '180px' }}
        transform={{
          base: 'translateX(-50%)',
          md: 'none',
        }}
      >
        <SimpleButton
          id="ieo-register-sticky-btn"
          borderRadius={{ base: '10px', md: '10px 0px 0px 10px !important' }}
          _hover={{
            background:
              '#CE6113 !important' || pageConfigVal?.btn?.bgHoverColor,
          }}
          mb="0"
          mt="0"
          clickHandler={() => {
            gtmClick();
          }}
          style={sectionContent[0]}
          region={region}
          lang={lang}
          background={'#F37021 !important' || pageConfigVal?.btn?.bgColor}
          boxShadow="0px 0.40741px 2.2037px 0px rgba(115, 50, 12, 0.05), 0px 1.79259px 4.56296px 0px rgba(115, 50, 12, 0.07), 0px 4.4px 9.1px 0px rgba(115, 50, 12, 0.09), 0px 8.47407px 17.83704px 0px rgba(115, 50, 12, 0.12), 0px 14.25926px 32.7963px 0px rgba(115, 50, 12, 0.14), 0px 22px 56px 0px rgba(115, 50, 12, 0.19)"
          p={{
            base: pageConfigVal?.btn?.bgHoverColor
              ? '11.5px 14px'
              : '11.5px 34px',
            md: '16px 20px',
          }}
          lineHeight="normal"
          icon={pageConfigVal?.btn?.icon}
          fontFamily={TopBannerSt[lang]?.buttonText.fontFamily}
          fontSize={{
            base: '20px',
            md: isV4 ? '16px' : '18px',
            lg: isV4 ? '16px' : '23px',
          }}
          display="flex"
          alignItems="flex-center"
          justifyContent="center"
          textAlign={pageConfigVal?.btn?.icon ? 'left' : 'center'}
        />
      </Box>
    )
  );
};

export default RegisterStickyButton;
